<script setup lang="ts">
    import LoadingSpinner from '@/components/LoadingSpinner.vue';
    import { useEmployeeAction, useEmployeeQuery } from '@/account/composables/employee';
    import DataTable, { type DataTablePageEvent } from 'primevue/datatable';
    import Column from 'primevue/column';
    import { useAsyncTask } from 'vue-concurrency';
    import { useI18n } from 'vue-i18n';
    import { isStringEmpty } from '@containex/common-utils';
    import { ref } from 'vue';
    import { DEFAULT_ROW_AMOUNT_OPTIONS_TABLE, DEFAULT_ROWS_PER_TABLE } from '@/constants';
    import { type FetchPaginatedDataDto, PaginationStep } from '@containex/portal-backend-dto';
    import { getLogger } from '@containex/logger';

    const { t } = useI18n();
    const { employees, totalEmployeeAmount } = useEmployeeQuery();
    const employeeAction = useEmployeeAction();
    const logger = getLogger('EmployeesView');

    const isLoading = ref(false);
    const paginatorCurrentRows = ref(DEFAULT_ROWS_PER_TABLE);
    const paginatorCurrentPage = ref(0);
    const paginatorPageLinkSize = ref(2);

    const task = useAsyncTask(async () => {
        await employeeAction.fetchEmployees(paginatorCurrentRows.value, PaginationStep.New);
    }).perform();

    const fetchPaginatedEmployees = useAsyncTask(async (signal, data: FetchPaginatedDataDto) => {
        logger.debug('EmployeesView', 'fetching employees for table');
        isLoading.value = true;
        await employeeAction.fetchEmployees(data.dataAmount, data.step);
        isLoading.value = false;
    }).drop();

    async function handlePaginatorChange(event: DataTablePageEvent): Promise<void> {
        if (paginatorCurrentPage.value === event.page && paginatorCurrentRows.value === event.rows) {
            return;
        }

        if (paginatorCurrentRows.value !== event.rows) {
            paginatorCurrentRows.value = event.rows;
            await fetchPaginatedEmployees.perform({
                dataAmount: event.rows,
                step: PaginationStep.New,
            });
            event.page = 0;
        } else {
            await fetchPaginatedEmployees.perform({
                dataAmount: event.rows,
                step: paginatorCurrentPage.value < event.page ? PaginationStep.Next : PaginationStep.Previous,
            });
        }

        paginatorCurrentPage.value = event.page;
        getPageLinkSize();
    }

    function getPageLinkSize(): void {
        const lastPage = Math.ceil(totalEmployeeAmount.value / paginatorCurrentRows.value) - 1;
        if (paginatorCurrentPage.value === 0 || paginatorCurrentPage.value === lastPage) {
            paginatorPageLinkSize.value = 2;
        } else {
            paginatorPageLinkSize.value = 3;
        }
    }
</script>
<template>
    <div class="employee-page-container">
        <h2 class="text-2xl-bold-line-height-auto">{{ t('ACCOUNT.MY_CTX_EMPLOYEES') }}</h2>
        <template v-if="task.isSuccessful">
            <BlockUI :blocked="fetchPaginatedEmployees.isRunning">
                <DataTable
                    v-if="(employees != null && employees.length > 0) || fetchPaginatedEmployees.isRunning"
                    lazy
                    :value="employees"
                    paginator
                    :total-records="totalEmployeeAmount"
                    :rows="paginatorCurrentRows"
                    :rows-per-page-options="DEFAULT_ROW_AMOUNT_OPTIONS_TABLE"
                    :loading="isLoading"
                    :page-link-size="paginatorPageLinkSize"
                    :first="paginatorCurrentPage * paginatorCurrentRows"
                    :current-page-report-template="
                        t('ACCOUNT.PAGINATOR', {
                            first: '{first}',
                            last: '{last}',
                            totalRecords: '{totalRecords}',
                        })
                    "
                    paginator-template="CurrentPageReport PrevPageLink PageLinks NextPageLink RowsPerPageDropdown"
                    @page="handlePaginatorChange"
                >
                    <Column field="gender" :header="t('ACCOUNT.EMPLOYEES.SALUTATION')">
                        <template #body="slotProps"> {{ t('ENUMS.SALUTATION.' + slotProps.data.gender) }}</template>
                    </Column>
                    <Column field="name" :header="t('ACCOUNT.EMPLOYEES.NAME')"></Column>
                    <Column field="email" :header="t('ACCOUNT.EMPLOYEES.EMAIL')">
                        <template #body="slotProps">
                            <a
                                v-if="!isStringEmpty(slotProps.data.email)"
                                :href="'mailto: ' + slotProps.data.email"
                                class="mail-link"
                            >
                                {{ slotProps.data.email }}
                            </a>
                        </template>
                    </Column>
                    <Column field="officePhone" :header="t('ACCOUNT.EMPLOYEES.OFFICE_PHONE')"></Column>
                    <Column field="mobilePhone" :header="t('ACCOUNT.EMPLOYEES.MOBILE_PHONE')"></Column>
                </DataTable>
                <p v-else>{{ t('ACCOUNT.EMPLOYEES.NO_EMPLOYEES') }}</p>
            </BlockUI>
        </template>
        <LoadingSpinner v-else-if="task.isRunning" />
    </div>
</template>

<style scoped lang="scss">
    @use 'src/styling/main';

    .employee-page-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        row-gap: main.$spacing-6;
    }

    .employee-image {
        width: 45px;
        height: 45px;
        vertical-align: middle;
    }

    .mail-link {
        color: main.$color-primary-500;
        text-decoration: none;
    }

    .mail-link:hover {
        color: main.$color-primary-darker;
        text-decoration: underline;
    }
</style>
